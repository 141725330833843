<template>
  <T3Link
    v-if="isButtonLinkValid && isButtonContentValid"
    class="button-base"
    :class="[variantClass, sizeClass, wrapClass]"
    :to="link ?? button?.link"
  >
    <span @click="onButtonClick">
      <template v-if="!slots.default">
        {{ (content || button?.content)?.trim() }}
      </template>

      <slot />
    </span>
  </T3Link>

  <button
    v-else-if="isButtonContentValid"
    class="button-base"
    :class="[variantClass, sizeClass, wrapClass]"
    @click="onButtonClick"
  >
    <span>
      <template v-if="!slots.default">
        {{ (content || button?.content)?.trim() }}
      </template>

      <slot />
    </span>
  </button>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue'

import { isLinkValid } from '~ui/helpers/linkUtils'
import type { UiButtonProps } from '~ui/types'

const slots = useSlots()

type ButtonBaseProps = Omit<UiButtonProps, 'isArrow'>

const props = defineProps<{ button?: ButtonBaseProps } & ButtonBaseProps>()

const isButtonLinkValid = computed(() =>
  isLinkValid(props.link ?? props.button?.link)
)
const isButtonContentValid = computed(() => {
  return (props.content ?? props.button?.content) || slots.default
})

const sizeArray = ['large', 'medium', 'small', 'extra-small']

const variantArray = [
  'solid-macopedia-blue',
  'outline-macopedia-blue',
  'solid-white',
  'outline-white',
  'solid-black',
  'outline-black',
  'solid-typo3-orange',
  'outline-typo3-orange'
]

const formatText = (string: string | undefined) =>
  string?.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() ?? ''

const setButtonClass = (opt: string | undefined, optArray: Array<string>) =>
  `button-base--${optArray.includes(formatText(opt)) ? formatText(opt) : optArray[0]}`

const variantClass = computed(() => {
  return setButtonClass(props.variant ?? props.button?.variant, variantArray)
})
const sizeClass = computed(() => {
  return setButtonClass(props.size ?? props.button?.size, sizeArray)
})
const wrapClass = computed(() => {
  return props.noWrap ?? props.button?.noWrap ? 'button-base--no-wrap' : ''
})

const emit = defineEmits(['click'])
const onButtonClick = () => {
  emit('click')
}
</script>

<style lang="scss">
.button-base {
  @include button-base;

  &--no-wrap {
    white-space: nowrap;
  }

  // variants
  &--solid-macopedia-blue {
    @include button-style(color(white), color(cerulean), 2.5);
  }

  &--outline-macopedia-blue {
    @include button-style(color(cerulean), color(white), 2);
  }

  &--solid-white {
    @include button-style(
      color(cerulean),
      color(white),
      2,
      $border-color: color(white)
    );
  }

  &--outline-white {
    @include button-style(
      color(white),
      color(cerulean),
      2,
      $border-color: color(white)
    );
  }

  &--solid-black {
    @include button-style(color(white), color(black), -1.75);
  }

  &--outline-black {
    @include button-style(color(black), color(white), -1.75);
  }

  &--solid-typo3-orange {
    @include button-style(color(white), color(flush-orange), 3);
  }

  &--outline-typo3-orange {
    @include button-style(color(flush-orange), color(white), 3);
  }

  // sizes
  &--large > span {
    padding-block: em(12px);
    height: rem(48px);
  }

  &--medium > span {
    padding-block: em(8px);
    height: rem(40px);
  }

  &--small > span {
    padding-block: em(4px);
    height: rem(32px);
  }

  &--extra-small > span {
    padding-block: em(0px);
    height: rem(24px);
    font-size: rem(10px);
  }
}
</style>
